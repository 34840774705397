<template>
  <div id="app">
    <div v-if="isInPC"  class="pc-box" ref="pcBox"  >
        <div class="code-button" @mouseover="showCode(true)" @mouseout="showCode(false)" @click="showCode(true)">
        <img src="./assets/img/shengdao_btn_scanning.png" alt=""></div>
        <div class="code" v-if="isShowCode">
        <img src="./assets/img/shengdao_btn_qrbg.png" class="qr-out" alt="">
        <img src="./assets/origin_img_v2_e2ddf85e-1db3-471c-8691-e49e897afd9g.jpg" alt="" class="qr-code">
        </div>
        <swiper :options="pcSwiperOption" ref="mySwiper">
            <!-- slides -->
            <swiper-slide>
            <div class="slide-bg slide-bg-1"></div>
            <div class="page-content shengdao_image_friend"></div>
            </swiper-slide>
            <swiper-slide>
            <div class="slide-bg slide-bg-2"></div>
            <div class="page-content shengdao_image_moment"></div>
            </swiper-slide>
            <swiper-slide>
            <div class="slide-bg slide-bg-3"></div>
            <div class="page-content shengdao_image_message" ></div>
            </swiper-slide>
            <swiper-slide>
            <div class="slide-bg slide-bg-4"></div>
            <div class="page-content shengdao_logo_slogan"></div>
            </swiper-slide>
            <!-- Optional controls -->
            <div class="swiper-pagination"  slot="pagination"></div>
            <!-- <div class="swiper-scrollbar"   slot="scrollbar"></div> -->
        </swiper> 
        <div class="top-container top-logo"></div>
        <div class="top-container top-tips-mouse"></div>
        <div class="top-container top-tips-text">
            <p>上海小煎饼信息科技有限公司</p>  
            <p>增值电信业务经营许可证编号：沪B2-20201258</p> 
            <!-- <p>网络文化经营许可证编号：沪网文【2020】4746-323号</p>   -->
            <p>网络文化经营许可证编号：<a class="license-key-text-decoration" target="blank" href="https://zwfw.mct.gov.cn/whscjydw?type=gb" >沪网文【2020】4746-323号</a> </p>
            <div class="license-key">
            <a class="license-key-text" target="blank" href="https://beian.miit.gov.cn/?spm=a2c4g.11174386.n2.3.21c361dbUY0BG9" >ICP备案 / 许可证号 沪ICP备2020030205号</a> 
            </div>
            <p>公司地址:上海市普陀区中江路879弄26号楼2042室 联系电话:87300535</p> 
            <div class="license-key">
            <a class="license-key-text" target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44010602010614">
                <img src="./assets/origin_img_v2_1ca57120-62de-4497-a35e-382d42d43ddg.png"/>
                <span>粤公网安备 44010602010614号</span>
            </a>
            </div>
        </div>
    </div>

    <div v-if="isInMobile"  class="mobile-box" ref="mobileBox">
        <swiper :options="mobileSwiperOption" ref="mySwiper">
            <!-- slides -->
            <swiper-slide>
                <div class="slide-bg slide-bg-1"></div>
                <div class="page-content shengdao_image_friend2"></div>
                <div class="page-content first-page-download-btn" @click="onClickDownload" v-if="!isIOS"><span>点击下载</span></div>
            </swiper-slide>
            <swiper-slide>
                <div class="slide-bg slide-bg-2"></div>
                <div class="page-content shengdao_image_moment2"></div>
            </swiper-slide>
            <swiper-slide>
                <div class="slide-bg slide-bg-3"></div>
                <div class="page-content shengdao_image_message2" ></div>
            </swiper-slide>
            <swiper-slide>
                <!-- <div class="slide-bg slide-bg-4"></div> -->
                <div class="page-content shengdao_logo_slogan2"></div>
                <div class="slide-4-tips-text">
                    <p>上海小煎饼信息科技有限公司</p>  
                    <p>增值电信业务经营许可证编号：沪B2-20201258</p> 
                    <!-- <p>网络文化经营许可证编号：沪网文【2020】4746-323号</p>   -->
                    <p>网络文化经营许可证编号：<a class="license-key-text-decoration" target="blank" href="https://zwfw.mct.gov.cn/whscjydw?type=gb" >沪网文【2020】4746-323号</a> </p>
                    <div class="license-key">
                    <a class="license-key-text" target="blank" href="https://beian.miit.gov.cn/?spm=a2c4g.11174386.n2.3.21c361dbUY0BG9" >ICP备案 / 许可证号 沪ICP备2020030205号</a> 
                    </div>
                    <div class="license-key">
                    <a class="license-key-text" arget="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44010602010614">
                        <img src="./assets/origin_img_v2_1ca57120-62de-4497-a35e-382d42d43ddg.png"/>
                        <p>粤公网安备 44010602010614号</p>
                    </a>
                    </div>
                    <p>公司地址:上海市普陀区中江路879弄26号楼2042室 联系电话:87300535</p> 
                    
                </div>
            </swiper-slide>
            <!-- Optional controls -->
            <div class="swiper-pagination"  slot="pagination"></div>
            <!-- <div class="swiper-scrollbar"   slot="scrollbar"></div> -->
        </swiper> 
        <div class="top-container top-logo"></div>  
        <div class="bottom-download" v-if="mobileSwiperActiveIndex > 0 && !isIOS">
            <div class="bottom-download-left">
                <div class="bottom-download-logo"></div>
                <div class="bottom-download-text">
                    <span>上岛，遇见声音同好</span>
                </div>
            </div>
            <div class="bottom-download-right">
                <div class="bottom-download-btn" @click="onClickDownload">
                    <span>点击下载</span>
                </div>
            </div>
        </div>
        <div class="download-modal" @click.stop="onCloseModal" v-if="isShowDownloadModal">
            <div class="download-modal-box">
                <div class="modal-box-top">
                    <div class="modal-title">下载声岛</div>
                    <div class="modal-text">遇见声音同好</div>
                </div>
                <div class="modal-box-btn">
                    <div class="btn cancel-download-btn" @click.stop="onCloseModal">暂不下载</div>
                    <div class="btn right-download-btn" @click.stop="onDownload">立即下载</div>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import './less/app.less'

import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import 'swiper/css/swiper.css';

let mobileSwiperActiveIndex = 0
export default Vue.extend({
    name: 'App',
    components: {
        Swiper,
        SwiperSlide,
    },
    data(){
        const that = this
        return {
            pcSwiperOption: {  
                notNextTick: true,
                loop:false,
                initialSlide:0,
                mousewheel: true,
                effect : 'slide',
                direction : 'vertical',
                on: {
                    slideChangeTransitionEnd(){
                    console.log(this.activeIndex);
                    //切换结束时，告诉我现在是第几个slide
                    },
                },
                
                //分页器设置         
                pagination: {
                    el: '.swiper-pagination',
                    clickable :true
                }
            },
            isShowCode:false,
            pcSwiperSlides: [1, 2, 3, 4],
            sizeRatio:0,
            screenWidth:0,
            screenHeight:0,
            isInPC:false,
            isInMobile:false,
            mobileSwiperOption: {  
                notNextTick: true,
                loop:false,
                initialSlide:0,
                mousewheel: true,
                effect : 'slide',
                direction : 'vertical',
                on: {
                    slideChangeTransitionEnd(){
                        that.mobileSwiperActiveIndex = this.activeIndex
                        //切换结束时，告诉我现在是第几个slide
                        console.log('slide',that.mobileSwiperActiveIndex);
                    },

                },
                //分页器设置         
                pagination: {
                    el: '.swiper-pagination',
                    clickable :true
                }
            },
            mobileSwiperSlides: [1, 2, 3, 4],
            mobileSwiperActiveIndex:0,
            yybUrl:'https://sj.qq.com/appdetail/com.shengdao', //声岛应用宝链接
            blurST:0,
            isShowDownloadModal:false,
        }
    },
    watch: {

    },
    created(){  

    },
    computed:{
        isIOS(){
            if (navigator.userAgent.match(/(iPhone|iPod|iPad);?/i)) {
                return true;
            } else {
                return false;
            }
        }
    },
    mounted(){
        this.init()
    },
    methods:{
        showCode(val){
            this.isShowCode = val
        },
        init(){
            if(this.IsPC() && !this.isXiaoMiPhone()){
                //在pc端
                this.isInPC = true
                this.isInMobile = false
                setTimeout(() =>{
                this.resetScreenSize()
                window.onresize = () => {
                    this.resetScreenSize()
                }
                },500)
                
            }else{
                //在移动端
                this.isInMobile = true
                this.isInPC = false
            }
        },  
        IsPC() {
            const userAgentInfo = navigator.userAgent;
            const Agents = ["Android", "iPhone",
                "SymbianOS", "Windows Phone",
                "iPad", "iPod"
            ];
            let flag = true;
            for (let v = 0; v < Agents.length; v++) {
                if (userAgentInfo.indexOf(Agents[v]) > 0) {
                    flag = false;
                    break;
                }
            }
            return flag;
        },
        isXiaoMiPhone() {
            const userAgentInfo = navigator.userAgent.toLocaleLowerCase();
            if (userAgentInfo.indexOf("linux") > 0 && userAgentInfo.indexOf("xiaomi") > 0) return true
            return false
        },
        resetScreenSize(){
            this.screenWidth = this.$refs.pcBox.offsetWidth
            this.screenHeight = this.$refs.pcBox.offsetHeight

            const boundaryWidth = 1200;
            let comparisonHeight = 0;
            if (this.screenWidth > boundaryWidth) {
                comparisonHeight = 1080
                this.sizeRatio = this.screenHeight / comparisonHeight;
            }else{
                comparisonHeight = 900
                this.sizeRatio = this.screenHeight / comparisonHeight;
            }

            this.sizeRatio = 1

        },
        getRatioStyle(property){
            const _property = property
            for (const key in _property) {
                if (!isNaN(_property[key])) {
                    _property[key] = `${Math.ceil(_property[key] * this.sizeRatio)}px`;
                }
            }
            return  _property
        },
        onClickDownload(){
            console.log("download")
            if (this.isInWeixin()) {
                location.href = this.yybUrl;
                return;
            }
            if (this.blurST) return
            let packageUrl = this.getAndroidTargetUrl()
            location.href = packageUrl
            this.blurST = setTimeout(()=> {
                this.isShowDownloadModal = true
                clearTimeout(this.blurST);
                this.blurST = 0
            }, 1500);

            document.addEventListener("visibilitychange", function(e) {
                this.isShowDownloadModal = false
                clearTimeout(this.blurST);
                this.blurST = 0
            });
        },
        isInWeixin() {
            let ua = window.navigator.userAgent.toLowerCase();
            if (ua.match(/MicroMessenger/i) == 'micromessenger') {
                return true;
            } else {
                return false;
            }
        },
        getAndroidTargetUrl() {
            let page = ""
            let page2 = ""
            let search = ""
            let qparm = this.getUrlParam('qparm')

            if (qparm && qparm == UU_SharedPacketFrom_Beliked_Report) { //特殊处理吸引力报告
                search = "?page=goto_tab&tab=find_friend&sec_tab=show_like_report&from=7&notify=1"
                page = "goto_tab"
            } else {
                page = this.getUrlParam('page')
                page2 = this.getUrlParam('page2')
                search = decodeURI(window.location.search)
            }
            let targetUrl = "shengdao://"
            if (page) {
                targetUrl += page
                if (page2) {
                    targetUrl += '/' + page2
                }
                search = search.substr(1)
                let searchArr = search.split("&")
                for (let i = 0; i < searchArr.length; i++) {

                    if (searchArr[i].indexOf('page=') < 0 && searchArr[i].indexOf('page2=') < 0 && searchArr[i].indexOf('feVer=') < 0) {
                        let separator = '?'
                        if (targetUrl.indexOf('?') >= 0) {
                            separator = '&'
                        }
                        targetUrl += separator + searchArr[i]
                    }
                }
                return targetUrl
            } else {
                return targetUrl + "open"
            }

        },
        getUrlParam(name, url) {
            let re = new RegExp("[\\?&#]" + name + "=([^&#]+)", "gi");
            let ma = (url || location.href).match(re);
            let strArr;
            if (ma && ma.length > 0) {
                strArr = (ma[ma.length - 1]);
                let _index = strArr.indexOf("=");
                return strArr.substring(_index + 1);
            }
            return '';
        },
        onCloseModal(){
            this.isShowDownloadModal = false
        },
        onDownload(){
            this.onCloseModal()
            location.href = this.yybUrl;
        },
        
    }
});
</script>

<style lang="less">

body,html{
    width:100%;
    height:100%;
    overflow: hidden;
}
#app {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width:100%;
    height:100%;
    background-image:linear-gradient(#7E7EFF, #A67BF2);
    .pc-box {
        width:100%;
        min-width:1400px;
        height:100%;
        min-height:700px;
        margin:0 auto;
        user-select: none;
        -webkit-user-select:none; /*webkit浏览器*/
        position: relative;
        .swiper-container,.swiper-wrapper {
            width: 100%;
            height: 100%;
            z-index: 1;
        }
        .swiper-slide{
        width: 100%;
        height: 100%;
        z-index: 1;
        position: relative;
        .slide-bg{
            position: absolute;
            width:100%;
            height:100%;
            z-index: 1;
            &.slide-bg-1,&.slide-bg-3{
            background:url(./assets/img/shengdao_bg_circular1.png) no-repeat;
            background-size:100% 100%;
            }
            &.slide-bg-2,&.slide-bg-4{
            background:url(./assets/img/shengdao_bg_circular2.png) no-repeat;
            background-size:100% 100%;
            }
        }
        .page-content{
            position: absolute;
            z-index:2;
            p{
                font-family: PingFangSC-Regular;
                font-size: 26px;
                color: #FFFFFF;
                letter-spacing: 0;
                line-height: 32px;
            }
            &.shengdao_image_friend{ 
                width: 70%;
                height:100%;
                margin :auto;
                top:0;
                bottom:0;
                left: 0;
                right:0;
                background-image: url("./assets/img/shengdao_image_friend.png");
                background-repeat: no-repeat;
                background-position: center;
                background-size: cover;
                overflow: hidden;
            }
            &.shengdao_image_moment{ 
                width: 70%;
                height:100%;
                margin :auto;
                top:0;
                bottom:0;
                left: 0;
                right:0;
                background-image: url("./assets/img/shengdao_image_moment.png");
                background-repeat: no-repeat;
                background-position: center;
                background-size: cover;
                overflow: hidden;
            }
            &.shengdao_image_message{ 
                width: 70%;
                height:100%;
                margin :auto;
                top:0;
                bottom:0;
                left: 0;
                right:0;
                background-image: url("./assets/img/shengdao_image_message.png");
                background-repeat: no-repeat;
                background-position: center;
                background-size: cover;
                overflow: hidden;
            }
            &.page_text_3 {
                top:637px;
                left:390px;
            }
            &.shengdao_logo_slogan {
                width: 366px;
                height:260px;
                background:url(./assets/img/shengdao_logo_slogan.png) no-repeat;
                background-size:100% 100%;
                margin:auto;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
            }
            } 
        }
        .swiper-container-vertical > .swiper-pagination-bullets{
            right:50px;
        }
        .swiper-pagination-bullet{
            width:8px;
            height:8px;
            border-radius: 10px;
            opacity: 0.5;
            background: #FFFFFF;
            margin: 12px auto!important;
        }
        .swiper-pagination-bullet-active{
            width:14px;
            height:14px;
            background: #FFFFFF;
            border-radius: 10px;
            opacity: 1;
        }
        .top-container{
            position: absolute;
            z-index: 2;
        &.top-logo{
            width:115px;
            height:50px;
            top:50px;
            left:50px;
            background:url(./assets/img/shengdao_logo.png) no-repeat;
            background-size:100% 100%;
        }
        &.top-tips-mouse{
            width:28px;
            height:164px;
            top: 50%;
            transform: translate3d(0px, -50%, 0);
            left:50px;
            background:url(./assets/img/shengdao_tips_mouse.png) no-repeat;
            background-size:100% 100%;
        }
        &.top-tips-text{
            user-select:text;
            -webkit-user-select: text;
            left: 50%;
            transform: translateX(-50%);
            bottom: 30px;

            p,a{
                font-family: PingFangSC-Regular;
                font-size: 10px;
                color: #FFFFFF;
                text-align: center;
                line-height:14px;
            }
            
            .license-key{
                display: flex;
                justify-content: center;
            .license-key-text{
                display:flex;
                flex-direction: row;
                justify-content:center;
                align-items: center;
                color:#FFFFFF;
                text-decoration: underline;
                >img{
                    display:block;
                    width:20px;
                    height:20px;
                    float:left;
                    margin: 5px;
                }
            
            }
            
            }
        } 
        }
    }
    .mobile-box{
        width:100%;
        height:100%;
        position: relative;
        .swiper-container,.swiper-wrapper {
            width: 100%;
            height: 100%;
            z-index: 1;
        }
        .swiper-slide{
            width: 100%;
            height: 100%;
            z-index: 1;
            position: relative;
            display:flex;
            flex-direction:column;
            justify-content: center;
            align-items: center;
        .slide-bg{
            position: absolute;
            width:100%;
            height:100%;
            z-index: 1;
        }
        .page-content{
            position: absolute;
            z-index:2;
            p{
                font-family: PingFangSC-Regular;
                font-size: 26px;
                color: #FFFFFF;
                letter-spacing: 0;
                line-height: 32px;
            }
            &.shengdao_image_friend2{ 
                width: 100%;
                height:100%;
                margin :auto;
                top:0;
                bottom:0;
                left: 0;
                right:0;
                background-image: url("./assets/mobile/shengdao_image_friend2.png");
                background-repeat: no-repeat;
                background-position: center;
                background-size: cover;
                overflow: hidden;
            }
            &.first-page-download-btn{
                display: flex;
                justify-content:center;
                align-items: center;
                position: absolute;
                width: 124px;
                height:46px;
                margin :auto;
                bottom:22px;
                left: 0;
                right:0;

                background-image: linear-gradient(-86deg, #AEFFCF 0%, #22E6FF 100%);
                border-radius: 54pt;
                font-family: PingFang-SC-Medium;
                font-size: 18px;
                color: #010024;
                text-align: center;
                z-index:100;

            }
            &.shengdao_image_moment2{ 
                width: 100%;
                height:100%;
                margin :auto;
                top:0;
                bottom:0;
                left: 0;
                right:0;
                background-image: url("./assets/mobile/shengdao_image_moment2.png");
                background-repeat: no-repeat;
                background-position: center;
                background-size: cover;
                overflow: hidden;
            }
            &.shengdao_image_message2{ 
                width: 100%;
                height:100%;
                margin :auto;
                top:0;
                bottom:0;
                left: 0;
                right:0;
                background-image: url("./assets/mobile/shengdao_image_message2.png");
                background-repeat: no-repeat;
                background-position: center;
                background-size: cover;
                overflow: hidden;
            }
            &.page_text_3 {
                top:637px;
                left:390px;
            }
            &.shengdao_logo_slogan2 {
                width: 183px;
                height:130px;
                background:url(./assets/mobile/shengdao_logo_slogan.png) no-repeat;
                background-size:100% 100%;
                display:flex;
                position:unset;
            }
            } 
            .slide-4-tips-text{
                display:flex;
                flex-direction: column;
                margin-top:37px;
                width:100%;
                font-size:11px;
                text-align: center;
                line-height:18px;
                color: #fff;
                .license-key{
                    display: flex;
                    justify-content: center;
                    .license-key-text{
                        display:flex;
                        flex-direction: row;
                        justify-content:center;
                        align-items: center;
                        color:#FFFFFF;
                        text-decoration: underline;
                    >img{
                        display:block;
                        width:20px;
                        height:20px;
                        float:left;
                        margin: 5px;
                    }
                    
                    }
                    
                }
            }
        }
        .swiper-container-vertical > .swiper-pagination-bullets{
            right:15px;
            width:8px;
            display:flex;
            flex-direction: column;
            justify-content:center;
            align-items:center;
        }
        .swiper-pagination-bullet{
            display:flex;
            width:4px;
            height:4px;
            border-radius: 10px;
            opacity: 0.5;
            background: #FFFFFF;
            margin: 6px 2px!important;
        }
        .swiper-pagination-bullet-active{
            width:8px;
            height:8px;
            background: #FFFFFF;
            border-radius: 10px;
            margin: 6px 0px!important;
            opacity: 1;
        }
        .top-container{
            position: absolute;
            z-index: 2;
            &.top-logo{
                width:57px;
                height:25px;
                top:25px;
                left:25px;
                background:url(./assets/mobile/shengdao_logo2.png) no-repeat;
                background-size:100% 100%;
            }
        }

    }
    .code-button{
        
            width: 178px;
            height: 50px;
            position: absolute;
            right: 50px;
            top: 50px;
            z-index: 2;
        img{
            width: 178px;
            height: 50px;
        }
    }
    .code{
            width: 200px;
            height: 212px;
            position: absolute;
            right: 39px;
            top: 108px;
            .qr-out{
            width: 200px;
            height: 212px;
        }
        .qr-code{
            width: 160px;
            height: 160px;
            position: absolute;
            top:32px;
            right: 20px;
        }
    }
}
.bottom-download{
    position: fixed;
    width: 100%;
    height:70px;
    bottom: 0;
    left: 0;
    background-color:rgba(0, 0, 0, 0.4);
    z-index: 99;
    display:flex;
    flex-direction:row;
    justify-content: space-between;
    align-items: center;
    .bottom-download-left{
        display:flex;
        flex-direction:row;
        justify-content: center;
        align-items: center;
        margin-left: 20px;
        .bottom-download-logo{
            display:flex;
            width:70px;
            height:30px;
            background:url(./assets/mobile/shengdao_logo2.png) no-repeat;
            background-size:100% 100%;
        }
        .bottom-download-text{
            margin-left:10px;
            margin-top: 2px;
            font-size:12px;
            line-height:18px;
            color: rgba(255,255,255,0.8);
        }

        
    }
    .bottom-download-right{
        display:flex;
        flex-direction:row;
        justify-content: center;
        align-items: center;
        margin-right: 20px;
        .bottom-download-btn{
            display: flex;
            justify-content:center;
            align-items: center;
            width: 110px;
            height:38px;
            background-image: linear-gradient(-86deg, #AEFFCF 0%, #22E6FF 100%);
            border-radius: 43px;
            font-family: PingFang-SC-Medium;
            font-size: 14px;
            color: #010024;

        }
    }
    
}
.download-modal {
    position: fixed;
    z-index: 2021;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    display:flex;
    flex-direction:row;
    justify-content: center;
    align-items: center;
    .download-modal-box{
        position: absolute;
        width: 240px;
        height: 150px;
        background: rgba(255, 255, 255, 1);
        border-radius: 7px;
        overflow: hidden;
        display:flex;
        flex-direction:column;
        justify-content: space-between;
        align-items: center;
        .modal-box-top{
            display:flex;
            flex:1;
            width:100%;
            flex-direction:column;
            justify-content: center;
            align-items: center;
            border-bottom: 1px solid #EAEAEA;
            >div{
                margin:5px 0;
            }
        }
        .modal-box-btn{
            display:flex;
            flex-direction:row;
            justify-content: space-around;
            align-items: center;
            width:100%;
            height: 50px;
            .btn{
                display:flex;
                flex:1;
                height:50px;
                justify-content: center;
                align-items: center;
            }
            .cancel-download-btn{
                border-right: 1px solid #EAEAEA;
                color: rgb(225, 225, 225);
            }
            .right-download-btn{
                color: #4cd964;
            }

        }
    }
}
.license-key-text-decoration{
    color:#FFFFFF;
    text-decoration: underline;
}
</style>
